<template>
  <div class="charge page">
    <Nav></Nav>
    <main class="wrap">
      <div class="content" v-html="content"></div>
    </main>
    <foot></foot>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";
export default {
  components: {
    foot,
    Nav,
  },
  data() {
    return {
      nid: 0,
      content: "",
    };
  },
  created() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.nid = obj.id;
    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      console.log(this.nid);
      //banner广告
      this.api
        .getArticle({
          id: this.nid,
        })
        .then((res) => {
          this.content = res.description;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">

.wrap{
  height: auto;
}
.content{
  padding-top: 30px;
}
</style>